<template>
  <KpiKorporatForm mode="Tambah" module="Indikator Kinerja"> </KpiKorporatForm>
</template>

<script>
import KpiKorporatForm from './form';

const KpiKorporatAdd = {
  name: 'KpiKorporatAdd',
  components: { KpiKorporatForm },
};

export default KpiKorporatAdd;
</script>
